import { Disclosure } from '@headlessui/react';
import classNames from '../../utils/classNames';
import Link from '../Link';

export default function Sidebar({ categories }) {
  return (
    <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <Link href="/" className="h-24 w-auto mx-auto">
          <img
            className="h-24 w-auto mx-auto"
            src="/logo.png"
            alt="Catholic Teachers Gazette"
          />
        </Link>
      </div>
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
          {categories.map((category) => (
            <Disclosure as="div" key={category.id} className="space-y-1">
              {({ open }) => (
                <>
                  <Disclosure.Button className="group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                    <svg
                      className={classNames(
                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                        'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                    <span className="flex-1 truncate">{category.name}</span>
                    <span className="ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full bg-gray-100 group-hover:bg-gray-200">
                      {category.jobCount}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1">
                    {category.positions.map((position) => (
                      <Link href={`/jobs/${position.id}`} key={position.id}>
                        <Disclosure.Button
                          as="div"
                          className="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-normal text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <span className="flex-1 truncate">
                            {position.title}
                          </span>
                          <span className="ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full bg-gray-100 group-hover:bg-gray-200">
                            {position.jobCount}
                          </span>
                        </Disclosure.Button>
                      </Link>
                    ))}
                    {!category?.positions?.length && (
                      <p className="w-full flex items-center pl-10 pr-2 py-2 text-sm font-normal text-gray-600">
                        No positions available
                      </p>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </nav>
      </div>
    </div>
  );
}
